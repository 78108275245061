body {
  background: #824caf;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin: 0;
}

header {
  background: #222;
  color: white;
  padding: 1rem;
  font-size: 3rem;
}

header * {
  max-width: 960px;
  margin: 0 auto;
}

header p {
  font-size: 2rem;
  font-weight: 100;
}

nav,
main {
  padding: 1rem;
  max-width: 960px;
  margin: 0 auto;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

nav li {
  margin-top: 2rem;
}

nav a {
  font-size: 2rem;
  font-weight: bold;
  color: inherit;
  text-decoration: none;
}

nav a:hover {
  opacity: 0.8;
}

nav hr {
  color: white;
  margin-top: 2rem;
}

nav sub {
  color: #ddd;
  display: block;
  font-size: 1rem;
  font-weight: 300;
  margin-top: 0.5rem;
}

nav input,
nav button {
  font-size: inherit;
}

button {
  background: none;
  color: inherit;
  border: 1px solid;
  border-radius: 6px;
  padding: 0.25rem 1rem;
  cursor: pointer;
  margin: 0.5rem 0;
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

button:hover:not([disabled]) {
  background: #fff3;
}

button:active:not([disabled]) {
  filter: brightness(0.9);
}

footer {
  font-size: 0.75rem;
  margin: 4rem auto;
  max-width: 960px;
  padding: 1rem;
  opacity: 0.5;
  text-align: center;
}

footer a {
  color: inherit;
}

/* prevent clicking on disabled links */
nav a[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

a,
a:visited,
a:active {
  color: inherit
}

a.help-icon {
  opacity: 0.5;
  text-decoration: none;
  margin-left: 0.5rem;
  cursor: pointer;
}

/* A modal popup */
#overlay {
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  visibility: hidden;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}

[data-help-id] {
  background: #fff;
  box-shadow: 0 0 10px #222;
  color: #222;
  font-size: 12pt;
  line-height: 1.5;
  margin: 10vh 2rem;
  max-height: 70vh;
  max-width: 32em;
  overflow-x: scroll;
  padding: 2rem;
}

[data-help-id] h2 {
  margin-top: 0;
  border-bottom: 4px dotted;
}
