body {
  color: #fff;
  background: #824caf;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

header {
  color: #fff;
  background: #222;
  padding: 1rem;
  font-size: 3rem;
}

header * {
  max-width: 960px;
  margin: 0 auto;
}

header p {
  font-size: 2rem;
  font-weight: 100;
}

nav, main {
  max-width: 960px;
  margin: 0 auto;
  padding: 1rem;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

nav li {
  margin-top: 2rem;
}

nav a {
  color: inherit;
  font-size: 2rem;
  font-weight: bold;
  text-decoration: none;
}

nav a:hover {
  opacity: .8;
}

nav hr {
  color: #fff;
  margin-top: 2rem;
}

nav sub {
  color: #ddd;
  margin-top: .5rem;
  font-size: 1rem;
  font-weight: 300;
  display: block;
}

nav input, nav button {
  font-size: inherit;
}

button {
  color: inherit;
  cursor: pointer;
  background: none;
  border: 1px solid;
  border-radius: 6px;
  margin: .5rem 0;
  padding: .25rem 1rem;
}

button:disabled {
  opacity: .5;
  cursor: default;
}

button:hover:not([disabled]) {
  background: #fff3;
}

button:active:not([disabled]) {
  filter: brightness(.9);
}

footer {
  max-width: 960px;
  opacity: .5;
  text-align: center;
  margin: 4rem auto;
  padding: 1rem;
  font-size: .75rem;
}

footer a {
  color: inherit;
}

nav a[disabled] {
  pointer-events: none;
  opacity: .5;
}

a, a:visited, a:active {
  color: inherit;
}

a.help-icon {
  opacity: .5;
  cursor: pointer;
  margin-left: .5rem;
  text-decoration: none;
}

#overlay {
  visibility: hidden;
  z-index: 100;
  background: #00000080;
  justify-content: center;
  display: flex;
  position: fixed;
  inset: 0;
}

[data-help-id] {
  color: #222;
  max-height: 70vh;
  max-width: 32em;
  background: #fff;
  margin: 10vh 2rem;
  padding: 2rem;
  font-size: 12pt;
  line-height: 1.5;
  overflow-x: scroll;
  box-shadow: 0 0 10px #222;
}

[data-help-id] h2 {
  border-bottom: 4px dotted;
  margin-top: 0;
}

/*# sourceMappingURL=index.c2326d27.css.map */
